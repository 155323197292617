<template>
    <div class="close_detail" style="background-image: url('img/close_detail.png');width: 100vw;background-repeat: no-repeat;background-size: 100% 100%;"></div>
</template>
<script>
 import "@/assets/css/index.css";
export default {
    data() {
        return {
         
        }
    },
    created() {
  

    },
    methods: {
                             
    }
}
</script>

